import L from 'leaflet';
import debounce from 'lodash/debounce';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import ApiFeaturedContent from '../../../../api/api-featured-content';
import ApiListings from '../../../../api/api-listings';
import { ListingDTO } from '../../../../api/model';
import GeoUtil from '../../../../lib/geo-util';
import { selectLeafletMap, selectMapZoom } from '../../../../store/App/selectors';
import { selectSelectedSearchTerm } from '../../../../store/Map/MapSelection/selectors';
import { LISTING_COUNT } from '../../../LandingPage/map-count-ticker';
import MapSearchDrawAOIResults from './map-search-draw-aoi-results';
import MapSearchDrawListingCount from './map-search-draw-listing-count';
import SoarHelper from '../../../../lib/soar-helper';

const RESULT_LIMIT = 25; // Api default
const LOAD_MORE_LIMIT = 20;
const MAP_MOVE_DEBOUNCE = 250;
const WORLD_BOUNDS = L.latLngBounds(L.latLng(-90, -180), L.latLng(90, 180));
const WORLD_BOUNDS_ZOOM_CUTOFF = 3;

const MapSearchDraw = () => {
    const [listings, setListings] = useState<ListingDTO[] | undefined>();
    const [firstLoadListings, setFirstLoadListings] = useState<ListingDTO[] | undefined>();
    const [listingLocationCount, setListingLocationCount] = useState<number | undefined>();
    const [listingsFilteredCount, setListingsFilteredCount] = useState<number | undefined>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const leafletMap = useSelector(selectLeafletMap);

    const [featuredListings, setFeaturedListings] = useState<ListingDTO[]>([]);
    const [newestListings, setNewestListings] = useState<ListingDTO[]>([]);
    const [globalListings, setGlobalListings] = useState<ListingDTO[]>([]);

    const searchTerm = useSelector(selectSelectedSearchTerm);
    const mapZoom = useSelector(selectMapZoom);

    useEffect(() => {
        loadFirstListings();
    }, []);

    useEffect(() => {
        if (mapZoom && mapZoom < WORLD_BOUNDS_ZOOM_CUTOFF) {
            setListings(firstLoadListings);
        }
    }, [mapZoom, firstLoadListings]);

    const loadFirstListings = () => {
        const aoiWKT = GeoUtil.latLngBoundsToWKT(WORLD_BOUNDS);

        ApiFeaturedContent.getHottestMaps().then((res) => {
            setFeaturedListings(res.slice(0, 5));
        });

        ApiListings.getListings({ limit: 6 }).then((res) => {
            setNewestListings(res.slice(0, 5));
        });

        ApiListings.fetchListingsByAOI(aoiWKT, RESULT_LIMIT, 0, undefined, undefined, '').then((res) => {
            // Use true count from the server
            //setFirstLoadListingsCount(res.locationTotal);

            // Use the estimate count from the landing page, that includes satellite data
            if (SoarHelper.isSoar()) {
                setListingLocationCount(LISTING_COUNT);
                setGlobalListings(res.listings);
            } else {
                setListingLocationCount(res.locationTotal);
            }
        });
    };

    useEffect(() => {
        if (featuredListings.length > 0 && newestListings.length > 0 && globalListings.length > 0) {
            setFirstLoadListings([...featuredListings, ...newestListings, ...globalListings]);
        }
    }, [featuredListings, newestListings, globalListings]);

    const cancelFetchListings = () => ApiListings.cancelAOIListing('AOI Changed');

    const fetchListings = useCallback(
        async (offset: number, loadMoreLimit?: number) => {
            if (!leafletMap) return;

            if (leafletMap.getZoom() < WORLD_BOUNDS_ZOOM_CUTOFF && !searchTerm && offset === 0) {
                if (!firstLoadListings) {
                    loadFirstListings();
                    return;
                } else {
                    setListings(firstLoadListings);
                    setListingLocationCount(LISTING_COUNT);
                    return;
                }
            }

            if (offset === 0) {
                setListings(undefined);
                setListingLocationCount(undefined);
                setListingsFilteredCount(undefined);
            }

            try {
                cancelFetchListings();
                setIsLoading(true);
                const aoiWKT = GeoUtil.latLngBoundsToWKT(leafletMap.getBounds());
                const data = await ApiListings.fetchListingsByAOI(
                    aoiWKT,
                    loadMoreLimit ? loadMoreLimit : RESULT_LIMIT,
                    offset,
                    undefined,
                    undefined,
                    searchTerm
                );

                if (data?.listings) {
                    if (!loadMoreLimit) {
                        setListings(data.listings);
                    } else {
                        setListings((prevListings) => [...(prevListings ?? []), ...data.listings]);
                    }

                    if (leafletMap.getZoom() < WORLD_BOUNDS_ZOOM_CUTOFF && !searchTerm) {
                        setListingLocationCount(LISTING_COUNT);
                    } else {
                        setListingLocationCount(data.locationTotal);
                        setListingsFilteredCount(data.filteredTotal);
                    }
                }
            } catch (error) {
                if (!error.toString().includes('Cancel')) {
                    console.log('Error fetching listings:', error);
                }
            } finally {
                setTimeout(() => setIsLoading(false), 300);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [leafletMap, searchTerm, firstLoadListings]
    );

    useEffect(() => {
        fetchListings(0);
    }, [fetchListings]);

    useEffect(() => {
        if (!leafletMap) return;
        const debouncedHandleMapMove = debounce(() => {
            fetchListings(0);
        }, MAP_MOVE_DEBOUNCE);

        leafletMap.on('moveend', debouncedHandleMapMove);
        leafletMap.on('zoomend', debouncedHandleMapMove);

        return () => {
            leafletMap.off('moveend', debouncedHandleMapMove);
            leafletMap.off('zoomend', debouncedHandleMapMove);
        };
    }, [leafletMap, fetchListings]);

    const handleLoadMore = debounce(() => {
        fetchListings(listings?.length ?? 0, LOAD_MORE_LIMIT);
    }, MAP_MOVE_DEBOUNCE);

    return (
        <MapSearchDrawContainer>
            <MapSearchDrawListingCount listingLocationCount={listingLocationCount} />
            <MapSearchResultsDivider />
            <MapSearchDrawAOIResults
                listings={listings}
                listingsCount={listingLocationCount || listingsFilteredCount || 0}
                isLoading={isLoading}
                onLoadMore={handleLoadMore}
            />
        </MapSearchDrawContainer>
    );
};

export default MapSearchDraw;

const MapSearchDrawContainer = styled.div`
    margin: 0px 0px 0px 7px;
`;

const MapSearchResultsDivider = styled.div`
    height: 1px;
    background-color: transparent;
    margin-top: 10px;
`;
