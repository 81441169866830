import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import Analytics from '../../../../../lib/user-analytics';
import {
    selectUploadToSuperMapError,
    selectUploadToSuperMapStatusReport,
} from '../../../../../store/Dashboard/selectors';
import DrawerHint from '../../../drawer-hint';
import { StyledButton } from '../../../../Shared/styled-button';
import UploadMapShareModal from './upload-map-share-modal';

interface UploadMapCompleteProps {
    onClickBack: () => void;
    file: File;
    onUploadComplete: () => void;
}

const UploadMapComplete = (props: UploadMapCompleteProps) => {
    const uploadError = useSelector(selectUploadToSuperMapError);
    const statusReport = useSelector(selectUploadToSuperMapStatusReport);
    const [showShareModal, setShowShareModal] = useState<boolean>(true);

    const [finished, setFinished] = React.useState<boolean>(false);
    useEffect(() => {
        if (statusReport && statusReport.file && statusReport.file.name === props.file.name) {
            if (statusReport.credentials && statusReport.status === 100) {
                setFinished(true);
                props.onUploadComplete();
            }
        }
    }, [statusReport, props.file.name, props.onUploadComplete, props]);

    const uploadNew = () => {
        Analytics.Event('Upload', 'Clicked upload another image');
        props.onClickBack();
    };

    return (
        <React.Fragment>
            {showShareModal ? (
                <UploadMapShareModal isOpen={showShareModal} onClose={() => setShowShareModal(false)} />
            ) : null}
            <Container>
                <React.Fragment>
                    <Content>
                        {finished ? (
                            <>
                                <DrawerHint>
                                    <TextUpdate>Congratulations! Your upload is complete.</TextUpdate>
                                </DrawerHint>
                                <Button onClick={uploadNew}>UPLOAD ANOTHER MAP</Button>
                            </>
                        ) : (
                            <DrawerHint>
                                <TextUpdate>Please do not close this window until upload is complete.</TextUpdate>
                            </DrawerHint>
                        )}
                    </Content>
                </React.Fragment>

                {uploadError ? (
                    <React.Fragment>
                        <Title>Upload Error</Title>
                        <Content>
                            <DrawerHint error>{uploadError.toString().replace('400: ', '')}</DrawerHint>
                        </Content>
                    </React.Fragment>
                ) : null}
            </Container>
        </React.Fragment>
    );
};

export default UploadMapComplete;

const Container = styled.div`
    margin: 40px auto;
    max-width: 370px;
`;

const Title = styled.h3`
    flex: 0 1 auto;
    color: white;
    text-align: left;
    font-weight: 700;
    font-size: 20px;
`;

const Content = styled.div`
    flex: 1 1 auto;
    padding-right: 12px;
    text-align: left;
    font-weight: 400;
    line-height: 20.8px;
    color: white;
    overflow-y: auto;
`;

const Button = styled(StyledButton)`
    margin: 60px auto 12px auto;
`;

const TextUpdate = styled.div`
    color: white;
`;
